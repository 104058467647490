
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import Papa from "papaparse";
import { ExportToCsv } from "export-to-csv";
import { uploadCsvDecoder } from "@/store/api/devices";
interface IHeader {
  name: string;
  key: string;
}
export default defineComponent({
  name: "add-repo",
  components: {
    //KTDatatable,
  },
  setup() {
    const file = ref("");
    const content = ref([]);
    const parsed = ref<boolean>(false);
    const loading = ref<string>("off");
    const header = ref<IHeader[]>([]);
    const data = ref<any[]>([]);
    const variant = ref<string>("");
    const searchValue = ref<any>("");
    const ajax_calls = ref<any[]>([]);
    const json_data = ref<any[]>([]);
    const nested_json_data = ref<any[]>([]);
    const downloadBtn = ref(false);
    const respCSV = ref<any>({
      added: [],
      errors: [],
      skipped: [],
      success: false,
    });
    let response: any = {};
    onMounted(() => {
      setCurrentPageBreadcrumbs("CSV Decoder", [""]);
    });

    const handleFileUpload = (event) => {
      loading.value = "on";
      file.value = event.target.files[0];
      parseFile();
    };
    const searchData = (val) => {
      if (val != "") {
        data.value = data.value.filter(
          (v) =>
            v["Device Address"] == val ||
            v["Unique Random Number"] == val ||
            v["Device EUI"] == val ||
            v["Application EUI"] == val ||
            v["Device Serial Number"] == val
        );
      } else {
        data.value = content.value;
      }

      // return value;
    };
    const parseFile = () => {
      Papa.parse(file.value, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          content.value = results.data;
          for (const key in content.value[0]) {
            let json = {
              key: key,
              name: key,
            };
            header.value.push(json);
          }
          data.value = content.value;
          parsed.value = true;
          loading.value = "off";
        }.bind(this),
      });
    };
    const submit = async () => {
      loading.value = "on";
      let payload = {
        variant: variant.value,
        devices: content.value,
      };
      let chunk = [...createChunks(payload.devices, 50)];

      for (var i = 0; i < chunk.length; i++) {
        let results = await uploadCsvDecoder(chunk[i]);
        ajax_calls.value.push(results.data);
      }
      downloadBtn.value = true;
      json_data.value = [].concat.apply([], ajax_calls.value);
      forkJoin(ajax_calls.value).subscribe(
        (data) => {
          for (var i = 0; i < chunk.length; i++) {
            respCSV.value = _.mergeWith(response, data[i], joinResults);
          }
          response = {};
          loading.value = "off";
        },
        (err) => {
          console.log(err);
        }
      );
    };
    const downloadcsv = () => {
      console.log("val", json_data.value);

      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "After Decoding the csv",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(json_data.value);
    };
    const isObject = (val: boolean) => {
      return typeof val === "object";
    };
    return {
      header,
      searchValue,
      searchData,
      data,
      parsed,
      content,
      respCSV,
      variant,
      handleFileUpload,
      submit,
      isObject,
      loading,
      ajax_calls,
      json_data,
      downloadcsv,
      downloadBtn,
    };
  },
});

function* createChunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

function joinResults(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
